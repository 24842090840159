import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./Context/Context";

// Components
import ScrollToTopButton from "./Components/ScrollToTop";

// Home
const TravelAgencyPage = lazy(() => import("./Pages/Home/TravelAgency"));
// About Pages
const AboutUsPage = lazy(() => import("./Pages/About/AboutUsPage"));
// Modal Page
const ModelPage = lazy(() => import("./Pages/AIPages/Model-page"));
const AIEthics = lazy(() => import("./Pages/AIPages/ai-ethics"));

// 404 page
const NotFoundPage = lazy(() => import("./Pages/404"));
const MaintenancePage = lazy(() =>
  import("./Pages/AdditionalPages/MaintenancePage")
);
const ComingSoonPage = lazy(() =>
  import("./Pages/AdditionalPages/ComingSoonPage")
);
function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false,
  });
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener("load", retina(document.querySelectorAll("img")));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then((module) => {
        module.SetHeaderMenuPos();
        module.setDocumentFullHeight();
      });
    }, 1000);
  }, [location]);

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            <ScrollToTopButton />
            <AnimatePresence mode="wait">
              <Suspense fallback={<></>}>
                <Routes>
                  {/* ai paths */}
                  {/* <Route path="/" element={<LandingPage />} /> */}
                  <Route path="/" element={<TravelAgencyPage />} />
                  <Route path="/ai-ethics" element={<AIEthics />} />
                  <Route
                    path="model/:id"
                    element={
                      <ModelPage
                      // style={{
                      //   "--base-color": "#0038e3",
                      //   background: "#f7f7f7",
                      // }}
                      />
                    }
                  />
                  <Route
                    path="/about-us"
                    element={
                      <AboutUsPage style={{ "--base-color": "#0038e3" }} />
                    }
                  />
                  <Route
                    path="/page/error-404"
                    element={
                      <NotFoundPage style={{ "--base-color": "#0038e3" }} />
                    }
                  />

                  {/* <Route
                    path="/page/maintenance"
                    element={<MaintenancePage />}
                  />
                  <Route
                    path="/page/coming-soon"
                    element={<ComingSoonPage />}
                  /> */}
                  <Route path="*" element={<NotFoundPage />} />
                  {/* Headers */}
                  {/* <Route path="headers">
                    <Route
                      path="transparent-header"
                      element={
                        <TransparentHeaderPage
                          style={{ "--base-color": "#0038e3" }}
                        />
                      }
                    />
                    <Route path="white-header" element={<WhiteHeaderPage />} />
                    <Route path="dark-header" element={<DarkHeaderPage />} />
                    <Route
                      path="header-with-top-bar"
                      element={
                        <HeaderwithTopbarPage
                          style={{ "--base-color": "#828282" }}
                        />
                      }
                    />
                    <Route
                      path="header-with-push"
                      element={
                        <HeaderWithPushPage
                          style={{ "--base-color": "#828282" }}
                        />
                      }
                    />
                    <Route
                      path="center-navigation"
                      element={<CenterNavigationPage />}
                    />{" "}
                    <Route path="center-logo" element={<CenterLogoPage />} />
                    <Route path="top-logo" element={<TopLogoPage />} />
                    <Route
                      path="one-page-navigation"
                      element={
                        <OnePageNavigationPage
                          style={{ "--base-color": "#f4d956" }}
                        />
                      }
                    />
                    <Route
                      path="header-always-fixed"
                      element={<HeaderAlwaysFixedPage />}
                    />{" "}
                    <Route
                      path="header-disable-fixed"
                      element={<HeaderDisableFixed />}
                    />
                    <Route
                      path="header-reverse-scroll"
                      element={<HeaderReverseScroll />}
                    />{" "}
                    <Route
                      path="header-responsive-sticky"
                      element={
                        <HeaderResponsiveSticky
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="left-menu-classic"
                      element={<LeftMenuClassicPage />}
                    />{" "}
                    <Route
                      path="left-menu-modern"
                      element={
                        <LeftMenuModernPage
                          style={{ "--base-color": "#c7da26" }}
                        />
                      }
                    />
                    <Route
                      path="mobile-menu-classic"
                      element={<MobileMenuClassicPage />}
                    />{" "}
                    <Route
                      path="mobile-menu-modern"
                      element={<MobileMenuModernPage />}
                    />
                    <Route
                      path="mobile-menu-full-screen"
                      element={<MobileMenuFullScreen />}
                    />{" "}
                    <Route
                      path="hamburger-menu"
                      element={<HamburgerMenuPage />}
                    />
                    <Route
                      path="hamburger-menu-modern"
                      element={
                        <HamburgerMenuModernPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="hamburger-menu-half"
                      element={
                        <HamburgerMenuHalfPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                  </Route> */}

                  {/* Footers */}
                  {/* <Route
                    path="footers"
                    element={<Footer style={{ "--base-color": "#0038e3" }} />}
                  >
                    <Route
                      path="footer-style-01"
                      element={
                        <FooterStyle01Page
                          style={{ "--base-color": "#0038e3" }}
                        />
                      }
                    />
                    <Route
                      path="footer-style-02"
                      element={
                        <FooterStyle02Page
                          style={{ "--base-color": "#0038e3" }}
                        />
                      }
                    />
                    <Route
                      path="footer-style-03"
                      element={
                        <FooterStyle03Page
                          style={{ "--base-color": "#0038e3" }}
                        />
                      }
                    />
                    <Route
                      path="footer-style-04"
                      element={
                        <FooterStyle04Page
                          style={{ "--base-color": "#0038e3" }}
                        />
                      }
                    />
                    <Route
                      path="footer-style-05"
                      element={
                        <FooterStyle05Page
                          style={{ "--base-color": "#0038e3" }}
                        />
                      }
                    />
                    <Route
                      path="footer-style-06"
                      element={
                        <FooterStyle06Page
                          style={{ "--base-color": "#0038e3" }}
                        />
                      }
                    />
                    <Route
                      path="footer-style-07"
                      element={
                        <FooterStyle07Page
                          style={{ "--base-color": "#0038e3" }}
                        />
                      }
                    />
                    <Route
                      path="footer-style-08"
                      element={
                        <FooterStyle08Page
                          style={{ "--base-color": "#0038e3" }}
                        />
                      }
                    />
                    <Route
                      path="footer-style-09"
                      element={
                        <FooterStyle09Page style={{ "--base-color": "#fff" }} />
                      }
                    />
                    <Route
                      path="footer-style-10"
                      element={
                        <FooterStyle10Page
                          style={{ "--base-color": "#0038e3" }}
                        />
                      }
                    />
                    <Route
                      path="footer-style-10"
                      element={
                        <FooterStyle10Page
                          style={{ "--base-color": "#0038e3" }}
                        />
                      }
                    />
                    <Route
                      path="footer-style-11"
                      element={
                        <FooterStyle11Page
                          style={{ "--base-color": "#0038e3" }}
                        />
                      }
                    />
                    <Route
                      path="footer-style-12"
                      element={
                        <FooterStyle12Page
                          style={{ "--base-color": "#0038e3" }}
                        />
                      }
                    />
                  </Route> */}

                  {/* Home Corporate*/}
                  {/* <Route
                    path="/home-startup"
                    element={
                      <HomeStartupPage style={{ "--base-color": "#27ae60" }} />
                    }
                  /> */}
                  {/* <Route
                    path="/home-business"
                    element={
                      <HomeBusinessPage style={{ "--base-color": "#3452ff" }} />
                    }
                  /> */}
                  {/* <Route
                    path="/home-corporate"
                    element={
                      <CorporatePage style={{ "--base-color": "#2ed47c" }} />
                    }
                  /> */}
                  {/* <Route
                    path="/home-finance"
                    element={
                      <FinancePage style={{ "--base-color": "#8bb867" }} />
                    }
                  />
                  <Route
                    path="/home-application"
                    element={
                      <ApplicationPage style={{ "--base-color": "#502e95" }} />
                    }
                  />
                  <Route
                    path="/home-consulting"
                    element={
                      <ConsultingPage style={{ "--base-color": "#bf8c4c" }} />
                    }
                  />
                  <Route
                    path="/home-digital-agency"
                    element={
                      <DigitalagencyPage
                        style={{ "--base-color": "#e6994e" }}
                      />
                    }
                  />
                  <Route
                    path="/home-seo-agency"
                    element={
                      <SeoAgencyPage style={{ "--base-color": "#374162" }} />
                    }
                  />
                  <Route
                    path="/home-events-conference"
                    element={
                      <EventsConferencePage
                        style={{ "--base-color": "#ff7a56" }}
                      />
                    }
                  />
                  <Route
                    path="/home-marketing-agency"
                    element={
                      <MarketingAgencyPage
                        style={{ "--base-color": "#f4d956" }}
                      />
                    }
                  /> */}

                  {/* Home Specialized */}
                  {/* <Route path="/home-restaurant" element={<RestaurantPage />} />
                  <Route
                    path="/home-architecture"
                    element={
                      <ArchitecturePage style={{ "--base-color": "#cee002" }} />
                    }
                  />
                  <Route
                    path="/home-hotel-resort"
                    element={
                      <HotelResortPage style={{ "--base-color": "#c89965" }} />
                    }
                  /> */}
                  {/* ahmad */}
                  {/* <Route
                    // path="/home-travel-agency"
                    path="/main-home"
                    element={
                      <TravelAgencyPage style={{ "--base-color": "#ff7a56" }} />
                    }
                  />
                  <Route
                    path="/home-yoga-meditation"
                    element={
                      <YogaMeditationPage
                        style={{ "--base-color": "#EA9D8D" }}
                      />
                    }
                  />
                  <Route
                    path="/home-gym-fitness"
                    element={
                      <GymFitnessPage style={{ "--base-color": "#ff7a56" }} />
                    }
                  />
                  <Route
                    path="/home-spa-salon"
                    element={
                      <SpaSalonPage style={{ "--base-color": "#ec606c" }} />
                    }
                  />
                  <Route
                    path="/home-cafe"
                    element={<CafePage style={{ "--base-color": "#cd9452" }} />}
                  />
                  <Route
                    path="/home-decor"
                    element={
                      <HomeDecorPage style={{ "--base-color": "#bf8c4c" }} />
                    }
                  />
                  <Route
                    path="/home-dentist"
                    element={
                      <DentistPage style={{ "--base-color": "#00b8b8" }} />
                    }
                  />
                  <Route
                    path="/home-interior-design"
                    element={
                      <InteriorDesignPage
                        style={{ "--base-color": "#c3964e" }}
                      />
                    }
                  /> */}

                  {/* Home Specialized */}
                  {/* <Route
                    path="/home-design-agency"
                    element={
                      <DesignagencyPage style={{ "--base-color": "#ffcc2e" }} />
                    }
                  />
                  <Route
                    path="/home-web-agency"
                    element={
                      <WebagencyPage style={{ "--base-color": "#ffcc2e" }} />
                    }
                  />
                  <Route
                    path="/home-creative-agency"
                    element={
                      <CreativeAgencyPage
                        style={{ "--base-color": "#cc754c" }}
                      />
                    }
                  />
                  <Route
                    path="/home-branding-agency"
                    element={
                      <BrandingagencyPage
                        style={{ "--base-color": "#cc754c" }}
                      />
                    }
                  />
                  <Route
                    path="/home-photography"
                    element={
                      <PhotographyPage style={{ "--base-color": "#c7da26" }} />
                    }
                  />
                  <Route
                    path="/home-personal-portfolio"
                    element={
                      <PersonalportfolioPage
                        style={{ "--base-color": "#ffeb04" }}
                      />
                    }
                  />
                  <Route
                    path="/home-interactive-portfolio"
                    element={
                      <InteractiveportfolioPage
                        style={{ "--base-color": "#ffeb04" }}
                      />
                    }
                  />
                  <Route
                    path="/home-creative-portfolio"
                    element={<CreativeportfolioPage />}
                  />
                  <Route
                    path="/home-vertical-portfolio"
                    element={<VerticalportfolioPage />}
                  />
                  <Route
                    path="/home-split-portfolio"
                    element={<SplitPortfolioPage />}
                  />
                  <Route path="/home-freelancer" element={<FreelancerPage />} /> */}

                  {/* Home Other */}
                  {/* <Route
                    path="/home-furniture-shop"
                    element={
                      <FurnitureShopPage
                        style={{ "--base-color": "#ff6437" }}
                      />
                    }
                  />
                  <Route
                    path="/home-fashion-shop"
                    element={
                      <FashionShopPage style={{ "--base-color": "#000" }} />
                    }
                  />
                  <Route
                    path="/home-magazine"
                    element={
                      <MagazinePage style={{ "--base-color": "#c89965" }} />
                    }
                  />
                  <Route
                    path="/home-lifestyle-blog"
                    element={
                      <LifestyleBlogPage
                        style={{ "--base-color": "#FE7A56" }}
                      />
                    }
                  />
                  <Route
                    path="/home-classic-blog"
                    element={<ClassicBlogPage />}
                  />
                  <Route
                    path="/home-blog-metro"
                    element={
                      <HomeBlogMetroPage
                        style={{ "--base-color": "#0038e3" }}
                      />
                    }
                  /> */}

                  {/* Elements */}
                  {/* <Route
                    path="elements"
                    element={
                      <ElementPage style={{ "--base-color": "#0038e3" }} />
                    }
                  >
                    <Route path="accordions" element={<AccordionPage />} />
                    <Route path="buttons" element={<ButtonPage />} />
                    <Route path="teams" element={<TeamPage />} />
                    <Route
                      path="team-carousel"
                      element={<TeamCarouselPage />}
                    />
                    <Route path="clients" element={<ClientPage />} />
                    <Route
                      path="client-carousel"
                      element={<ClientCarouselPage />}
                    />{" "}
                    <Route path="subscribe" element={<SubscribePage />} />
                    <Route
                      path="call-to-action"
                      element={<CallToActionPage />}
                    />
                    <Route path="tab" element={<TabPage />} />
                    <Route path="google-map" element={<GoogleMapPage />} />
                    <Route path="contact-form" element={<ContactFormPage />} />
                    <Route
                      path="image-gallery"
                      element={<ImageGalleryPage />}
                    />
                    <Route path="progress-bar" element={<ProgressBarPage />} />
                    <Route
                      path="icon-with-text"
                      element={<IconWithTextPage />}
                    />
                    <Route
                      path="overline-icon-box"
                      element={<OverLineIconBoxPage />}
                    />{" "}
                    <Route
                      path="custom-icon-with-text"
                      element={<CustomIconWithTextPage />}
                    />
                    <Route path="counters" element={<CountersPage />} />
                    <Route path="countdown" element={<CountDownPage />} />
                    <Route path="pie-chart" element={<PieChartPage />} />
                    <Route
                      path="fancy-text-box"
                      element={<FancyTextBoxPage />}
                    />
                    <Route path="text-box" element={<TextBoxPage />} />
                    <Route path="team" element={<TeamPage />} />
                    <Route path="fancy-text" element={<FancyTextPage />} />
                    <Route path="testimonials" element={<TestimonialsPage />} />
                    <Route
                      path="testimonials-carousel"
                      element={<TestimonialsCarouselPage />}
                    />{" "}
                    <Route path="video" element={<VideoPage />} />
                    <Route
                      path="interactive-banners"
                      element={<InteractiveBannersPage />}
                    />{" "}
                    <Route path="services" element={<ServicesPage />} />
                    <Route path="info-banner" element={<InfoBannerPage />} />
                    <Route path="rotate-box" element={<RotateBoxPage />} />
                    <Route path="process-step" element={<ProcessStepPage />} />
                    <Route path="instagram" element={<InstagramPage />} />
                    <Route
                      path="parallax-scrolling"
                      element={<ParallaxScrollingPage />}
                    />{" "}
                    <Route path="text-slider" element={<TextSliderPage />} />
                    <Route path="heading" element={<HeadingPage />} />
                    <Route path="dropcaps" element={<DropCapsPage />} />
                    <Route path="columns" element={<ColumnsPage />} />
                    <Route path="blockquote" element={<BlockquotePage />} />
                    <Route path="highlights" element={<HighlightsPage />} />
                    <Route path="message-box" element={<MessageBoxPage />} />
                    <Route path="social-icons" element={<SocialIconsPage />} />
                    <Route path="lists" element={<ListsPage />} />
                    <Route path="separators" element={<SeparatorsPage />} />
                    <Route
                      path="pricing-table"
                      element={<PricingTablePage />}
                    />
                  </Route> */}

                  {/* Portfolios */}
                  {/* <Route
                    path="portfolio"
                    element={
                      <PortfolioPage style={{ "--base-color": "#0038e3" }} />
                    }
                  >
                    <Route
                      path="portfolio-classic-two-column"
                      element={
                        <PortfolioClassicTwoColPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-classic-three-column"
                      element={
                        <PortfolioClassicThreeColPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-classic-four-column"
                      element={
                        <PortfolioClassicFourColPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-classic-masonry"
                      element={
                        <PortfolioClassicMasonryPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-classic-metro"
                      element={
                        <PortfolioClassicMetroPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-boxed-two-column"
                      element={
                        <PortfolioBoxedTwoColPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-boxed-three-column"
                      element={
                        <PortfolioBoxedThreeColPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-boxed-four-column"
                      element={
                        <PortfolioBoxedFourColPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-boxed-masonry"
                      element={
                        <PortfolioBoxedMasonryPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-boxed-metro"
                      element={
                        <PortfolioBoxedMetroPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-colorful-two-column"
                      element={
                        <PortfolioColorfulTwoColPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-colorful-three-column"
                      element={
                        <PortfolioColorfulThreeColPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-colorful-four-column"
                      element={
                        <PortfolioColorfulFourColPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-colorful-masonry"
                      element={
                        <PortfolioColorfulMasonryPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-colorful-metro"
                      element={
                        <PortfolioColorfulMetroPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-bordered-two-column"
                      element={
                        <PortfolioBorderedTwoColPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-bordered-three-column"
                      element={
                        <PortfolioBorderedThreeColPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-bordered-four-column"
                      element={
                        <PortfolioBorderedFourColPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-bordered-masonry"
                      element={
                        <PortfolioBorderedMasonryPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-bordered-metro"
                      element={
                        <PortfolioBorderedMetroPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-overlay-two-column"
                      element={
                        <PortfolioOverlayTwoColPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-overlay-three-column"
                      element={
                        <PortfolioOverlayThreeColPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-overlay-four-column"
                      element={
                        <PortfolioOverlayFourColPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-overlay-masonry"
                      element={
                        <PortfolioOverlayMasonryPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-overlay-metro"
                      element={
                        <PortfolioOverlayMetroPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-switch-image-two-column"
                      element={
                        <PortfolioSwitchImgTwoColPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-switch-image-three-column"
                      element={
                        <PortfolioSwitchImgThreeColPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-switch-image-four-column"
                      element={
                        <PortfolioSwitchImgFourColPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-switch-image-masonry"
                      element={
                        <PortfolioSwitchImgMasonryPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-switch-image-metro"
                      element={
                        <PortfolioSwitchImgMetroPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-scattered"
                      element={
                        <PortfolioScatteredPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-justified-gallery"
                      element={
                        <PortfolioJustifiedGalleryPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                    <Route
                      path="portfolio-slider"
                      element={
                        <PortfolioSliderPage
                          style={{ "--base-color": "#fff" }}
                        />
                      }
                    />
                  </Route> */}

                  {/* SingleProjectPage*/}
                  {/* <Route
                    path="/portfolio/single-project-page-01"
                    element={
                      <SingleProjectPage01
                        style={{ "--base-color": "#0038e3" }}
                      />
                    }
                  />
                  <Route
                    path="/portfolio/single-project-page-02"
                    element={
                      <SingleProjectPage02
                        style={{ "--base-color": "#0038e3" }}
                      />
                    }
                  />
                  <Route
                    path="/portfolio/single-project-page-03"
                    element={
                      <SingleProjectPage03
                        style={{ "--base-color": "#0038e3" }}
                      />
                    }
                  />
                  <Route
                    path="/portfolio/single-project-page-04"
                    element={
                      <SingleProjectPage04
                        style={{ "--base-color": "#fd961e" }}
                      />
                    }
                  />
                  <Route
                    path="/portfolio/single-project-page-05"
                    element={
                      <SingleProjectPage05
                        style={{ "--base-color": "#0038e3" }}
                      />
                    }
                  /> */}

                  {/* Blogs */}
                  {/* <Route
                    path="blogs"
                    element={<BlogPage style={{ "--base-color": "#0038e3" }} />}
                  >
                    <Route
                      path="blog-grid"
                      element={
                        <BlogGridPage style={{ "--base-color": "#0038e3" }} />
                      }
                    />
                    <Route
                      path="blog-masonry"
                      element={
                        <BlogMasonryPage
                          style={{ "--base-color": "#0038e3" }}
                        />
                      }
                    />
                    <Route path="blog-classic" element={<BlogClassicPage />} />
                    <Route path="blog-simple" element={<BlogSimplePage />} />
                    <Route
                      path="blog-side-image"
                      element={<BlogSideImPage />}
                    />
                    <Route path="blog-metro" element={<BlogMetroPage />} />
                    <Route
                      path="blog-overlay-image"
                      element={<BlogOverlayImgPage />}
                    />{" "}
                    <Route
                      path="blog-modern"
                      element={
                        <BlogModernPage style={{ "--base-color": "#0038e3" }} />
                      }
                    />
                    <Route path="blog-clean" element={<BlogCleanPage />} />
                    <Route path="blog-widget" element={<BlogWidgetPage />} />
                    <Route
                      path="blog-standard"
                      element={<BlogStandardPage />}
                    />
                    <Route path="category">
                      <Route path=":category" element={<CategoryPage />} />
                    </Route>
                    <Route path="author">
                      <Route path=":author" element={<AuthorPage />} />
                    </Route>
                  </Route> */}

                  {/* Blogs Types */}
                  {/* <Route path="blog-types">
                    <Route path="blog-standard-post">
                      <Route
                        path=":id"
                        element={
                          <BlogStandardPostPage
                            style={{ "--base-color": "#0038e3" }}
                          />
                        }
                      />
                    </Route>
                    <Route path="blog-gallery-post">
                      <Route
                        path=":id"
                        element={
                          <BlogGalleryPostPage
                            style={{ "--base-color": "#0038e3" }}
                          />
                        }
                      />
                    </Route>
                    <Route path="blog-slider-post">
                      <Route
                        path=":id"
                        element={
                          <BlogSliderPostPage
                            style={{ "--base-color": "#0038e3" }}
                          />
                        }
                      />
                    </Route>
                    <Route path="blog-html5-video-post">
                      <Route
                        path=":id"
                        element={
                          <BlogHtml5VideoPostPage
                            style={{ "--base-color": "#0038e3" }}
                          />
                        }
                      />
                    </Route>
                    <Route path="blog-youtube-video-post">
                      <Route
                        path=":id"
                        element={
                          <BlogYoutubeVideoPostPage
                            style={{ "--base-color": "#0038e3" }}
                          />
                        }
                      />
                    </Route>
                    <Route path="blog-vimeo-video-post">
                      <Route
                        path=":id"
                        element={
                          <BlogVimeoVideoPostPage
                            style={{ "--base-color": "#0038e3" }}
                          />
                        }
                      />
                    </Route>
                    <Route path="blog-audio-post">
                      <Route
                        path=":id"
                        element={
                          <BlogAudioPostPage
                            style={{ "--base-color": "#0038e3" }}
                          />
                        }
                      />
                    </Route>
                    <Route path="blog-blockquote-post">
                      <Route
                        path=":id"
                        element={
                          <BlogBlockquotePostPage
                            style={{ "--base-color": "#0038e3" }}
                          />
                        }
                      />
                    </Route>
                    <Route path="blog-full-width-post">
                      <Route
                        path=":id"
                        element={
                          <BlogFullWidthPost
                            style={{ "--base-color": "#0038e3" }}
                          />
                        }
                      />
                    </Route>
                  </Route> */}

                  {/* Model-Popup */}
                  {/* <Route path="model-popup" element={<ModalPopupPage />}>
                    <Route path="simple-modal" element={<SimpleModel />} />
                    <Route
                      path="subscription"
                      element={<SubscriptionModal />}
                    />
                    <Route path="contact-form" element={<ContactFormModal />} />
                    <Route
                      path="youtube-video"
                      element={<YouTubeVideoModal />}
                    />
                    <Route path="vimeo-video" element={<VimeoVideoModal />} />
                    <Route path="Google-map" element={<GoogleMapModal />} />
                  </Route> */}

                  {/* Icon Packs */}
                  {/* <Route path="iconsmindline" element={<IconsMindLinePage />} />
                  <Route
                    path="iconsmindsolid"
                    element={<IconsMindSolidPage />}
                  />
                  <Route path="ETlineicon" element={<EtLineIconPage />} />
                  <Route path="fontawesome" element={<FontAwesomeIconPage />} />
                  <Route path="feather" element={<FeatherIconPage />} />
                  <Route path="simple-line" element={<SimplelineIconPage />} />
                  <Route path="themify" element={<ThemifyIconPage />} />
                  <Route path="animation" element={<AnimationPage />} /> */}

                  {/* Page-Title */}
                  {/* <Route path="page-title" element={<PageTitle />}>
                    <Route
                      path="left-alignment"
                      element={<LeftAlignmentPage />}
                    />{" "}
                    <Route
                      path="right-alignment"
                      element={<RightAlignmentPage />}
                    />
                    <Route
                      path="center-alignment"
                      element={<CenterAlignmentPage />}
                    />{" "}
                    <Route
                      path="colorful-style"
                      element={<ColorfulStylePage />}
                    />
                    <Route
                      path="big-typography"
                      element={<BigTypographyPage />}
                    />{" "}
                    <Route
                      path="parallax-background"
                      element={<ParallaxBackground />}
                    />
                    <Route
                      path="separate-breadcrumbs"
                      element={<SeparateBreadcrumbsPage />}
                    />{" "}
                    <Route
                      path="gallery-background"
                      element={<GalleryBackgroundPage />}
                    />
                    <Route
                      path="background-video"
                      element={<BackgroundVideoPage />}
                    />{" "}
                    <Route path="mini-version" element={<MiniVersionPage />} />
                  </Route> */}

                  {/* PostLayout */}
                  {/* <Route
                    path="/blogs/blog-post-layout-01"
                    element={
                      <BlogPostLayout01 style={{ "--base-color": "#ca7741" }} />
                    }
                  />
                  <Route
                    path="/blogs/blog-post-layout-02"
                    element={
                      <BlogPostLayout02 style={{ "--base-color": "#c3964e" }} />
                    }
                  />
                  <Route
                    path="/blogs/blog-post-layout-03"
                    element={<BlogPostLayout03 />}
                  />
                  <Route
                    path="/blogs/blog-post-layout-04"
                    element={<BlogPostLayout04 />}
                  />
                  <Route
                    path="/blogs/blog-post-layout-05"
                    element={<BlogPostLayout05 />}
                  /> */}

                  {/*About Pages */}
                  {/* <Route
                    path="/page/about-me"
                    element={
                      <AboutMePage style={{ "--base-color": "#0038e3" }} />
                    }
                  />
                  <Route
                    path="/page/our-story"
                    element={
                      <OurStoryPage style={{ "--base-color": "#0038e3" }} />
                    }
                  />
                  <Route
                    path="/page/who-we-are"
                    element={
                      <WhoWeArePage style={{ "--base-color": "#0038e3" }} />
                    }
                  /> */}

                  {/* Services Pages */}
                  {/* <Route
                    path="/page/our-services"
                    element={
                      <OurServicesPage style={{ "--base-color": "#0038e3" }} />
                    }
                  />
                  <Route
                    path="/page/what-we-offer"
                    element={
                      <WhatWeOfferPage style={{ "--base-color": "#0038e3" }} />
                    }
                  />
                  <Route
                    path="/page/our-process"
                    element={
                      <OurProcessPage style={{ "--base-color": "#0038e3" }} />
                    }
                  /> */}

                  {/* Contact Pages */}
                  {/* <Route
                    path="/page/contact-simple"
                    element={
                      <ContactUsSimplePage
                        style={{ "--base-color": "#0038e3" }}
                      />
                    }
                  />
                  <Route
                    path="/page/contact-classic"
                    element={
                      <ContactUsClassicPage
                        style={{ "--base-color": "#0038e3" }}
                      />
                    }
                  />
                  <Route
                    path="/page/contact-modern"
                    element={
                      <ContactUsModernPage
                        style={{ "--base-color": "#0038e3" }}
                      />
                    }
                  /> */}

                  {/* Additional Pages */}
                  {/* <Route
                    path="/page/our-team"
                    element={
                      <OurTeamPage style={{ "--base-color": "#0038e3" }} />
                    }
                  />
                  <Route
                    path="/page/latest-news"
                    element={
                      <LatestNewsPage style={{ "--base-color": "#0038e3" }} />
                    }
                  />
                  <Route
                    path="/page/pricing-packages"
                    element={
                      <PricingPackagesPage
                        style={{ "--base-color": "#0038e3" }}
                      />
                    }
                  />
                  <Route
                    path="/page/error-404"
                    element={
                      <NotFoundPage style={{ "--base-color": "#0038e3" }} />
                    }
                  />
                  <Route
                    path="/page/maintenance"
                    element={<MaintenancePage />}
                  />
                  <Route
                    path="/page/coming-soon"
                    element={<ComingSoonPage />}
                  />
                  <Route
                    path="/page/coming-soon-V2"
                    element={<ComingSoonV2Page />}
                  />
                  <Route
                    path="/page/faq-s"
                    element={<FaqSPage style={{ "--base-color": "#0038e3" }} />}
                  />
                  <Route
                    path="/page/search-result"
                    element={
                      <SearchResultPage style={{ "--base-color": "#0038e3" }} />
                    }
                  />
                  
                  <Route
                    path="/privacy"
                    element={<Privacy style={{ "--base-color": "#0038e3" }} />}
                  /> */}

                  {/* Shop Pages */}
                  {/* <Route path="shop">
                    <Route path="shop-wide" element={<ShopWidePage />} />
                    <Route path="left-sidebar" element={<LeftSidebar />} />
                    <Route path="right-sidebar" element={<RightSidebar />} />
                    <Route
                      path="only-categories"
                      element={<OnlyCategoriesPage />}
                    />{" "}
                    <Route path="single-product" element={<SingleProduct />} />
                    <Route
                      path="shopping-cart"
                      element={
                        <ShoppingCart style={{ "--base-color": "#0038e3" }} />
                      }
                    />
                    <Route path="checkout" element={<Checkout />} />
                    <Route path="login-register" element={<LoginRegister />} />
                  </Route> */}
                </Routes>
              </Suspense>
            </AnimatePresence>
          </main>
        }
      </div>
    </GlobalContext.Provider>
  );
}

export default App;
